<svelte:window
  on:resize="{onResize}"
  on:click="{closePopupMenu}"
  on:blur="{closePopupMenu}"
/>
<div
  class="fd-app__sidebar {hideNavComponent ? 'hideNavComponent' : ''} {footerText || semiCollapsibleButton ? 'hasFooter' : ''} {footerText && !semiCollapsibleButton ? 'hasOnlyFooterText' : ''}"
>
  {#if navHeader}
  <div class="lui-nav-title">
    <ul class="fd-nested-list">
      <li class="fd-nested-list__item">
        <a class="fd-nested-list__link" title="{$getTranslation(navHeader.label)}">
          {#if navHeader.icon} {#if isOpenUIiconName(navHeader.icon)}
          <i
            class="lui-header-icon fd-nested-list__icon sap-icon sap-icon--{navHeader.icon}"
            role="presentation"
          ></i>
          {:else}
          <span class="fd-nested-list__icon sap-icon">
            <img
              src="{navHeader.icon}"
              alt="{navHeader.altText ? navHeader.altText : ''}"
            />
          </span>
          {/if} {/if}
          <span class="fd-nested-list__title">
            {$getTranslation(navHeader.label)}
          </span>
          {#if navHeader.showUpLink}
          <i
            class="lui-nav-up fd-nested-list__icon sap-icon sap-icon--navigation-up-arrow"
            role="presentation"
            title="{$getTranslation('luigi.navigation.up')}"
            on:click|preventDefault="{handleUp}"
          ></i>
          {/if}
        </a>
      </li>
    </ul>
  </div>
  {/if}
  <nav
    class="fd-side-nav {isSemiCollapsed ? 'fd-side-nav--condensed' : ''} {navHeader ? 'lui-nav-header-visible' : ''}"
    on:keyup="{handleKey}"
    data-testid="semiCollapsibleLeftNav"
  >
    {#if children && pathData.length > 1}
    <div class="fd-side-nav__main-navigation">
      <div class="lui-fd-side-nav-wrapper">
        <ul class="fd-nested-list {sideNavClass}">
          {#each sortedChildrenEntries as [key, nodes],index} {#if key === 'undefined' ||
          key.startsWith(virtualGroupPrefix)}
          <!-- Single nodes -->
          {#each nodes as node} {#if !node.hideFromNav} {#if node.label}
          <li class="fd-nested-list__item">
            <a
              href="{getRouteLink(node)}"
              title="{$getTranslation(node.label)}"
              class="fd-nested-list__link {node === selectedNode ? 'is-selected' : ''}"
              on:click|preventDefault="{() => handleClick(node)}"
              data-testid="{getTestId(node)}"
            >
              {#if node.icon} {#if isOpenUIiconName(node.icon)}
              <i
                class="fd-nested-list__icon sap-icon sap-icon--{node.icon}"
                role="presentation"
              ></i>
              {:else}
              <span class="fd-nested-list__icon sap-icon">
                <img src="{node.icon}" alt="{node.altText ? node.altText : ''}" />
              </span>
              {/if} {:else}
              <i
                class="fd-nested-list__icon sap-icon {isSemiCollapsed ? 'sap-icon--rhombus-milestone-2' : ''}"
                role="presentation"
              ></i>
              {/if}
              <span class="fd-nested-list__title">{$getTranslation(node.label)}</span>
              {#if node.externalLink && node.externalLink.url}
              <i
                class="fd-nested-list__icon sap-icon sap-icon--action"
                role="presentation"
              ></i>
              {/if} {#if node.badgeCounter}
              <BadgeCounter {node} />
              {/if}
            </a>
          </li>
          {/if} {/if} {/each} {:else}
          <!-- Collapsible nodes -->
          {#if nodes.metaInfo.collapsible}
          <li
            class="fd-nested-list__item lui-collapsible-item"
            on:click|stopPropagation="{() => handleIconClick(nodes, event.currentTarget)}"
            data-testid="{getTestIdForCat(nodes.metaInfo, key)}"
          >
            <div class="fd-nested-list__content has-child">
              <a
                href="javascript:void(null)"
                title="{$getTranslation(key)}"
                class="fd-nested-list__link {isExpanded(nodes, expandedCategories) ? 'is-expanded' : ''}"
                tabindex="-1"
                id="collapsible_listnode_{index}"
                aria-haspopup="true"
                aria-expanded="{isExpanded(nodes, expandedCategories)}"
                on:click|preventDefault="{() => setExpandedState(nodes, !isExpanded(nodes, expandedCategories), this)}"
              >
                {#if isOpenUIiconName(nodes.metaInfo.icon)}
                <i
                  class="fd-nested-list__icon sap-icon {'sap-icon--' + nodes.metaInfo.icon} {isSemiCollapsed && !nodes.metaInfo.icon ? 'sap-icon--rhombus-milestone-2' : ''}"
                  role="presentation"
                ></i>
                {:else}
                <span class="fd-nested-list__icon sap-icon">
                  <img
                    src="{nodes.metaInfo.icon}"
                    alt="{nodes.metaInfo.altText ? nodes.metaInfo.altText : ''}"
                  />
                </span>
                {/if}
                <span class="fd-nested-list__title">{$getTranslation(key)}</span>
              </a>
              <button
                class="fd-button fd-nested-list__button"
                href="#"
                tabindex="0"
                aria-label="Expand categories"
                aria-haspopup="true"
                aria-expanded="{isExpanded(nodes, expandedCategories)}"
                on:click|preventDefault="{() => setExpandedState(nodes, !isExpanded(nodes, expandedCategories), this)}"
              >
                <i
                  class="{isExpanded(nodes, expandedCategories) ? 'sap-icon--navigation-down-arrow' : 'sap-icon--navigation-right-arrow'}"
                  role="presentation"
                ></i>
              </button>
            </div>
            <ul
              class="fd-nested-list fd-nested-list--text-only level-2"
              aria-hidden="{!isExpanded(nodes, expandedCategories)}"
            >
              {#each nodes as node} {#if !node.hideFromNav} {#if node.label}
              <li
                class="fd-nested-list__item"
                aria-labelledby="collapsible_listnode_{index}"
              >
                <a
                  href="{getRouteLink(node)}"
                  class="fd-nested-list__link {node === selectedNode ? 'is-selected' : ''}"
                  on:click|preventDefault="{() => handleClick(node)}"
                  data-testid="{getTestId(node)}"
                  title="{$getTranslation(node.label)}"
                >
                  <span class="fd-nested-list__title">{$getTranslation(node.label)}</span>
                  {#if node.externalLink && node.externalLink.url}
                  <i class="sap-icon--action"></i>
                  {/if} {#if node.badgeCounter}
                  <BadgeCounter {node} />
                  {/if}
                </a>
              </li>
              {/if} {/if} {/each}
            </ul>
            <!-- Flyout for collapsible nodes -->
            {#if nodes.metaInfo && nodes.metaInfo.label === selectedCategory}
            <div class="lui-flyout-sublist">
              <div class="lui-flyout-sublist__wrapper">
                <h5
                  class="lui-flyout-sublist__title fd-has-type-minus-1 fd-has-color-text-4"
                >
                  {$getTranslation(key)}
                </h5>
                <ul class="fd-nested-list fd-nested-list--text-only">
                  {#each nodes as node} {#if !node.hideFromNav} {#if node.label}
                  <li class="fd-nested-list__item">
                    <a
                      href="{getRouteLink(node)}"
                      class="fd-nested-list__link {node === selectedNode ? 'is-selected' : ''}"
                      on:click|preventDefault="{() => handleClick(node)}"
                      data-testid="{getTestId(node)}"
                      title="{$getTranslation(node.label)}"
                    >
                      <span class="fd-nested-list__title"
                        >{$getTranslation(node.label)}</span
                      >
                      {#if node.externalLink && node.externalLink.url}
                      <i class="sap-icon--action"></i>
                      {/if} {#if node.badgeCounter}
                      <BadgeCounter {node} />
                      {/if}
                    </a>
                  </li>
                  {/if} {/if} {/each}
                </ul>
              </div>
            </div>
            {/if}
          </li>
          {:else}
          <!-- Category nodes -->
          <li
            class="fd-nested-list__group-header lui-category"
            title="{$getTranslation(key)}"
            data-testid="{getTestIdForCat(nodes.metaInfo, key)}"
            id="category_list_level1_{index}"
          >
            {#if hasCategoriesWithIcon && nodes.metaInfo.icon} {#if
            isOpenUIiconName(nodes.metaInfo.icon)}
            <i
              class="fd-nested-list__icon sap-icon {nodes.metaInfo.icon ? 'sap-icon--' + nodes.metaInfo.icon : ''}"
              role="presentation"
            ></i>
            {:else}
            <span class="fd-nested-list__icon sap-icon">
              <img
                src="{nodes.metaInfo.icon}"
                alt="{nodes.metaInfo.altText ? nodes.metaInfo.altText : ''}"
              />
            </span>
            {/if} {/if} {$getTranslation(key)}
          </li>
          {#each nodes as node} {#if !node.hideFromNav} {#if node.label}
          <li
            class="fd-nested-list__item"
            title="{$getTranslation(node.label)}"
            aria-labelledby="category_list_level1_{index}"
          >
            <a
              href="{getRouteLink(node)}"
              class="fd-nested-list__link {node === selectedNode ? 'is-selected' : ''}"
              on:click|preventDefault="{() => handleClick(node)}"
              data-testid="{getTestId(node)}"
            >
              {#if node.icon} {#if isOpenUIiconName(node.icon)}
              <i class="fd-nested-list__icon sap-icon sap-icon--{node.icon}"></i>
              {:else}
              <span class="fd-nested-list__icon sap-icon">
                <img src="{node.icon}" alt="{node.altText ? node.altText : ''}" />
              </span>
              {/if} {:else}
              <i
                class="fd-nested-list__icon sap-icon {isSemiCollapsed ? 'sap-icon--rhombus-milestone-2' : ''}"
              ></i>
              <span>{isSemiCollapsed ? 'sap-icon--rhombus-milestone-2' : ''}</span>
              {/if}
              <span class="fd-nested-list__title">{$getTranslation(node.label)}</span>
              {#if node.externalLink && node.externalLink.url}
              <i class="sap-icon--action"></i>
              {/if} {#if node.badgeCounter}
              <BadgeCounter {node} />
              {/if}
            </a>
          </li>
          {/if} {/if} {/each} {/if} {/if} {/each}
        </ul>
      </div>
    </div>
    {/if} {#if footerText || semiCollapsibleButton}
    <div class="fd-side-nav__utility">
      <span class="lui-side-nav__footer">
        <span class="lui-side-nav__footer--text fd-has-type-minus-1"
          >{footerText ? footerText : ''}</span
        >
        {#if semiCollapsibleButton}
        <i
          class="lui-side-nav__footer--icon {isSemiCollapsed ? 'sap-icon--open-command-field' : 'sap-icon--close-command-field'}"
          on:click="{() => semiCollapsibleButtonClicked(this)}"
          data-testid="semiCollapsibleButton"
          title="{burgerTooltip}"
        ></i>
        {/if}
      </span>
    </div>
    {/if}
  </nav>
</div>

<script>
  import { beforeUpdate, createEventDispatcher, onMount, getContext } from 'svelte';
  import { Navigation } from './services/navigation';
  import { Routing } from '../services/routing';
  import {
    IframeHelpers,
    NavigationHelpers,
    GenericHelpers,
    RoutingHelpers,
    StateHelpers,
    EventListenerHelpers
  } from '../utilities/helpers';
  import { LuigiConfig, LuigiElements, LuigiI18N, LuigiNavigation } from '../core-api';
  import { CSS_BREAKPOINTS } from '../utilities/constants';
  import { SemiCollapsibleNavigation } from './services/semi-collapsed-navigation';
  import BadgeCounter from './BadgeCounter.html';

  //TODO refactor
  const __this = {
    get: () => ({
      children,
      hideNavComponent,
      footerText,
      semiCollapsible,
      pathData,
      virtualGroupPrefix,
      isSemiCollapsed,
      selectedNode,
      sideNavAccordionMode,
      selectedCategory,
      expandedCategories,
      hasCategoriesWithIcon,
      navParentNode
    }),
    set: obj => {
      if (obj) {
        Object.getOwnPropertyNames(obj).forEach(prop => {
          if (prop === 'pathData') {
            pathData = obj.pathData;
          } else if (prop === 'context') {
            context = obj.context;
          } else if (prop === 'children') {
            children = obj.children;
          } else if (prop === 'selectedNode') {
            selectedNode = obj.selectedNode;
          } else if (prop === 'hasCategoriesWithIcon') {
            hasCategoriesWithIcon = obj.hasCategoriesWithIcon;
          }
        });

        navHeader = undefined;
        let parentNode = obj.navParent;
        navParentNode = parentNode;
        if (
          parentNode &&
          parentNode.navHeader &&
          GenericHelpers.requestExperimentalFeature('navHeader', true)
        ) {
          let resolvedNavHeader = parentNode.navHeader;
          let resolvedNavHeaderNode = parentNode;

          if ('inherit' === resolvedNavHeader) {
            resolvedNavHeaderNode = parentNode.parent;
            while (
              resolvedNavHeaderNode &&
              'inherit' === resolvedNavHeaderNode.navHeader
            ) {
              resolvedNavHeaderNode = resolvedNavHeaderNode.parent;
            }
            resolvedNavHeader = resolvedNavHeaderNode
              ? resolvedNavHeaderNode.navHeader
              : undefined;
          }

          if (resolvedNavHeader instanceof Function) {
            navHeader = {};

            let res = resolvedNavHeader(
              NavigationHelpers.stripNode(parentNode),
              NavigationHelpers.stripNode(resolvedNavHeaderNode),
              context
            );
            if (res instanceof Promise) {
              res.then(headerData => {
                navHeader = headerData;
              });
            } else {
              navHeader = res;
            }
          } else if ('auto' === resolvedNavHeader) {
            navHeader = {
              label: parentNode.label,
              icon: parentNode.icon
            };
          } else if (
            resolvedNavHeader &&
            resolvedNavHeader.useTitleResolver &&
            resolvedNavHeaderNode.titleResolver
          ) {
            if (resolvedNavHeaderNode.titleResolver.prerenderFallback) {
              navHeader = {
                ...resolvedNavHeader,
                label: resolvedNavHeaderNode.titleResolver.fallbackTitle || '',
                icon: resolvedNavHeaderNode.titleResolver.fallbackIcon
              };
            } else {
              navHeader = resolvedNavHeader;
            }

            const route = RoutingHelpers.mapPathToNode(
              Routing.getCurrentPath(),
              resolvedNavHeaderNode
            );

            Navigation.extractDataFromPath(route).then(data => {
              const ctx = RoutingHelpers.substituteDynamicParamsInObject(
                Object.assign({}, data.pathData.context, resolvedNavHeaderNode.context),
                data.pathData.pathParams
              );
              NavigationHelpers.fetchNodeTitleData(resolvedNavHeaderNode, ctx)
                .then(headerData => {
                  navHeader = { ...resolvedNavHeader, ...headerData };
                })
                .catch(error => {
                  console.error('Error while retrieving title, fallback to node label');
                  navHeader = {
                    ...resolvedNavHeader,
                    label: parentNode.label,
                    icon: parentNode.icon
                  };
                });
            });
          } else {
            navHeader = resolvedNavHeader;
          }
        }

        let sideNavAccordionModeOverride =
          (selectedNode && selectedNode.sideNavAccordionMode) ||
          (selectedNode &&
            selectedNode.parent &&
            selectedNode.parent.sideNavAccordionMode);
        if (typeof sideNavAccordionModeOverride !== 'undefined') {
          sideNavAccordionMode = sideNavAccordionModeOverride;
        } else {
          sideNavAccordionMode = LuigiConfig.getConfigBooleanValue(
            'navigation.defaults.sideNavAccordionMode'
          );
        }
      }
    }
  };

  const dispatch = createEventDispatcher();

  export let children;
  export let hideNavComponent;
  export let footerText;
  export let semiCollapsible;
  export let semiCollapsibleButton;
  export let pathData;
  export let pathParams;
  let previousPathData;
  export let virtualGroupPrefix = NavigationHelpers.virtualGroupPrefix;
  export let isSemiCollapsed;
  export let selectedNode;
  export let selectedCategory = null;
  export let expandedCategories;
  export let hasCategoriesWithIcon;
  export let sideNavClass;
  export let sideNavAccordionMode;
  export let burgerTooltip;
  export let navHeader;
  export let navParentNode;
  let context;
  let previousWindowWidth;
  let responsiveNavSetting;
  let sideNavCompactMode;
  let store = getContext('store');
  let getTranslation = getContext('getTranslation');

  const setLeftNavData = async () => {
    const componentData = __this.get();
    const leftNavData = await Navigation.getLeftNavData(
      { ...componentData },
      componentData
    );
    if (!leftNavData) {
      return;
    }
    __this.set(leftNavData);
    previousPathData = pathData;
    window.LEFTNAVDATA = leftNavData.children;
  };

  onMount(() => {
    semiCollapsibleButton =
      LuigiConfig.getConfigValue('settings.responsiveNavigation') === 'semiCollapsible';
    hideNavComponent = LuigiConfig.getConfigBooleanValue('settings.hideNavigation');
    sideNavCompactMode = LuigiConfig.getConfigBooleanValue('settings.sideNavCompactMode');
    expandedCategories = NavigationHelpers.loadExpandedCategories();

    StateHelpers.doOnStoreChange(
      store,
      () => {
        footerText = LuigiConfig.getConfigValue('settings.sideNavFooterText');
      },
      ['settings.footer']
    );

    // set compact mode class
    if (Boolean(sideNavCompactMode)) {
      sideNavClass = 'fd-nested-list fd-nested-list--compact';
    } else {
      sideNavClass = 'fd-nested-list';
    }
    let stateArr = SemiCollapsibleNavigation.initial();
    isSemiCollapsed = stateArr.isSemiCollapsed;
    semiCollapsible = stateArr.semiCollapsible;
    SemiCollapsibleNavigation.onValueChanged(stateArr => {
      isSemiCollapsed = stateArr.isSemiCollapsed;
    });

    EventListenerHelpers.addEventListener('message', e => {
      if ('luigi.navigation.update-badge-counters' === e.data.msg) {
        setLeftNavData();
      }
    });
  });

  beforeUpdate(() => {
    if (!previousPathData || previousPathData != pathData) {
      setLeftNavData();
    }
  });

  export let sortedChildrenEntries;
  $: {
    if (children) {
      const entries = Object.entries(children);
      entries.sort((e1, e2) => e1[1].metaInfo.order - e2[1].metaInfo.order);
      sortedChildrenEntries = entries;
    }
  }

  function isOpenUIiconName(name) {
    return NavigationHelpers.isOpenUIiconName(name);
  }

  function isExpanded(nodes, expandedList) {
    return expandedList && expandedList.indexOf(nodes.metaInfo.categoryUid) >= 0;
  }

  function getTestId(node) {
    return node.testId
      ? node.testId
      : NavigationHelpers.prepareForTests(node.pathSegment, node.label);
  }

  function getRouteLink(node) {
    return RoutingHelpers.getNodeHref(node, pathParams);
  }

  function getTestIdForCat(metaInfo, key) {
    return metaInfo && metaInfo.testId
      ? metaInfo.testId
      : NavigationHelpers.prepareForTests(key || metaInfo.label);
  }

  // [svelte-upgrade suggestion]
  // review these functions and remove unnecessary 'export' keywords
  export function handleClick(node) {
    dispatch('handleClick', { node });
  }

  export function handleIconClick(nodeOrNodes, el) {
    if (SemiCollapsibleNavigation.getCollapsed()) {
      let selectedCat;
      let sideBar = document.getElementsByClassName('fd-app__sidebar')[0];

      if (nodeOrNodes.metaInfo && nodeOrNodes.metaInfo.label) {
        selectedCat = nodeOrNodes.metaInfo.label;
      } else {
        selectedCat =
          (nodeOrNodes.category && nodeOrNodes.category.label) || nodeOrNodes.category;
      }

      if (!sideBar.classList.contains('isBlocked')) {
        sideBar.className += ' isBlocked';
      }

      // only close if same clicked
      if (selectedCat === selectedCategory) {
        selectedCategory = SemiCollapsibleNavigation.closePopupMenu(selectedCategory);
        return;
      }

      selectedCategory = selectedCat;

      calculateFlyoutPosition(el);
    }
  }

  export function calculateFlyoutPosition(el) {
    //Calculate top/bottom position for flyout sublist
    const parent = el.closest('.fd-nested-list__item');
    const parentTopPosition = parent.offsetTop;
    const shellbarHeight = LuigiElements.getShellbar().offsetHeight;
    let containerHeight;
    if (LuigiElements.isCustomLuigiContainer()) {
      containerHeight = LuigiElements.getCustomLuigiContainer().clientHeight;
    } else {
      containerHeight = window.innerHeight;
    }
    setTimeout(() => {
      const flyoutSublist = parent.getElementsByClassName('lui-flyout-sublist')[0];
      const topScroll = el.closest('.lui-fd-side-nav-wrapper').scrollTop;
      const topPosition = parentTopPosition + shellbarHeight - topScroll;
      const bottomPosition =
        containerHeight -
        parentTopPosition -
        parent.offsetHeight +
        topScroll -
        shellbarHeight;

      if (topPosition + flyoutSublist.offsetHeight >= containerHeight) {
        flyoutSublist.style.bottom = bottomPosition + 'px';
        flyoutSublist.className += ' has-bottom-position';
      } else {
        flyoutSublist.style.top = topPosition - shellbarHeight + 'px';
      }
    });
  }

  export function onResize() {
    if (semiCollapsible) {
      let stateArr = SemiCollapsibleNavigation.onResize(selectedCategory);
      isSemiCollapsed = stateArr.isSemiCollapsed;
      selectedCategory = stateArr.selectedCategory;
    }
  }

  export function handleKey(event) {
    const code = event.code;
    if (code === 'ArrowRight') {
      const iframe = IframeHelpers.getCurrentMicrofrontendIframe();
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.focus();
      }
    }
  }

  export function handleUp(event) {
    let node = navParentNode ? navParentNode.parent : undefined;
    while (node) {
      if (node.pathSegment && node.pathSegment.trim().indexOf(':') !== 0) {
        dispatch('handleClick', { node });
        return;
      }
      node = node.parent;
    }

    console.warn('Could not resolve "up"-node, redirecting to root');
    LuigiNavigation.navigate('/');
  }

  export function setExpandedState(nodes, value) {
    if (SemiCollapsibleNavigation.getCollapsed()) {
      return;
    }

    expandedCategories = NavigationHelpers.storeExpandedState(
      nodes.metaInfo.categoryUid,
      value,
      sideNavAccordionMode
    );
  }

  export function closePopupMenu() {
    selectedCategory = SemiCollapsibleNavigation.closePopupMenu(selectedCategory);
  }

  function semiCollapsibleButtonClicked(el) {
    isSemiCollapsed = SemiCollapsibleNavigation.buttonClicked();
    if (document.getElementsByClassName('fd-tabs').length > 0) {
      dispatch('resizeTabNav', {});
    }
    setBurgerTooltip();
  }

  function setBurgerTooltip() {
    if (!NavigationHelpers.getBurgerTooltipConfig()) {
      return;
    }
    const [
      collapseNavTooltip,
      expandNavTooltip
    ] = NavigationHelpers.getBurgerTooltipConfig();
    const hasSemiCollapsible = document.body.classList.contains('lui-semiCollapsible');
    if (collapseNavTooltip && expandNavTooltip && hasSemiCollapsible) {
      burgerTooltip = document.body.classList.contains('semiCollapsed')
        ? collapseNavTooltip
        : expandNavTooltip;
    }
  }
</script>

<style type="text/scss">:root {
  /* needed for IE11 support */
  --fd-color-neutral-2: #eeeeef;
  --fd-color-neutral-3: #d9d9d9; }

a {
  cursor: pointer;
  outline-offset: -1px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

:global(html.luigi-app-in-custom-container) .fd-app__sidebar {
  position: absolute; }

:global(.lui-global-nav-visible) .fd-app__sidebar {
  left: 69px; }
  :global(.lui-global-nav-visible) .fd-app__sidebar .fd-side-nav {
    width: var(--luigi__left-sidenav--width); }

:global(.semiCollapsed) .lui-fd-side-nav-wrapper {
  scrollbar-width: none; }
  :global(.semiCollapsed) .lui-fd-side-nav-wrapper::-webkit-scrollbar {
    width: 0; }

:global(body:not(.semiCollapsed)) .fd-app__sidebar .fd-side-nav.lui-nav-header-visible {
  height: auto;
  top: 3.25rem;
  position: absolute;
  bottom: 0; }

:global(.semiCollapsed) .lui-nav-title {
  display: none; }

.fd-app__sidebar {
  position: fixed;
  top: 2.75rem;
  left: 0;
  bottom: 0;
  z-index: 1; }
  .fd-app__sidebar .lui-fd-side-nav-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
  .fd-app__sidebar .lui-nav-title {
    height: 3.25rem;
    background: green;
    width: var(--luigi__left-sidenav--width);
    border-bottom: var(--sapList_BorderWidth, 0.0625rem) solid var(--sapList_BorderColor, #e4e4e4);
    border-right: var(--sapList_BorderWidth, 0.0625rem) solid var(--sapGroup_ContentBorderColor, #d9d9d9); }
    .fd-app__sidebar .lui-nav-title > ul {
      height: 100%; }
      .fd-app__sidebar .lui-nav-title > ul > li {
        height: 100%; }
    .fd-app__sidebar .lui-nav-title .fd-nested-list__link,
    .fd-app__sidebar .lui-nav-title .fd-nested-list__link:active {
      height: 100%;
      padding-left: 0.5rem;
      background: none;
      cursor: default; }
      .fd-app__sidebar .lui-nav-title .fd-nested-list__link .fd-nested-list__title,
      .fd-app__sidebar .lui-nav-title .fd-nested-list__link:active .fd-nested-list__title {
        font-weight: bold;
        padding-left: 0.3rem;
        color: var(--sapTextColor, #32363a); }
      .fd-app__sidebar .lui-nav-title .fd-nested-list__link .lui-header-icon,
      .fd-app__sidebar .lui-nav-title .fd-nested-list__link:active .lui-header-icon {
        font-size: 1.5rem;
        color: var(--sapTile_TextColor, #6a6d70); }
      .fd-app__sidebar .lui-nav-title .fd-nested-list__link .lui-nav-up,
      .fd-app__sidebar .lui-nav-title .fd-nested-list__link:active .lui-nav-up {
        cursor: pointer;
        color: var(--sapTile_TextColor, #6a6d70); }
  .fd-app__sidebar .fd-side-nav {
    height: 100%;
    width: var(--luigi__left-sidenav--width); }
    .fd-app__sidebar .fd-side-nav.fd-side-nav--condensed {
      width: 2.75rem; }
  .fd-app__sidebar .fd-side-nav__main-navigation {
    height: 100%;
    overflow: hidden; }
  .fd-app__sidebar .fd-side-nav__utility {
    margin-top: 0; }
    .fd-app__sidebar .fd-side-nav__utility:before {
      display: none; }
  .fd-app__sidebar.hasFooter .fd-side-nav__main-navigation {
    height: calc(100% - 2rem); }
  .fd-app__sidebar .lui-flyout-sublist {
    position: absolute; }

:global(.fd-nested-list__item):not(.lui-collapsible-item) .fd-nested-list__link {
  padding-right: 1rem; }

.fd-nested-list__link:not(.has-child) .fd-nested-list__title {
  padding-right: 5px; }

.fd-nested-list__icon img {
  max-width: 18px;
  max-height: 18px; }

:global(.fd-nested-list--compact) .fd-nested-list__icon img {
  max-width: 16px;
  max-height: 16px; }

.fd-nested-list__group-header .fd-nested-list__icon {
  margin-left: -1rem;
  font-size: 0.875rem;
  color: var(--sapContent_IconColor, #0854a0);
  height: 74%; }
  .fd-nested-list__group-header .fd-nested-list__icon img {
    max-width: 0.875rem;
    max-height: 0.875rem; }

/*TODO: check if FD Styles >v.0.17 included it*/
.lui-category {
  border-top: var(--sapList_BorderWidth, 0.0625rem) solid var(--sapList_GroupHeaderBorderColor, #d8d8d8); }

.lui-fd-side-nav-wrapper {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: transparent;
  -webkit-transition: width 0.1s linear;
  -moz-transition: width 0.1s linear;
  -ms-transition: width 0.1s linear;
  -o-transition: width 0.1s linear;
  transition: width 0.1s linear; }

.lui-side-nav__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: var(--sapList_BorderWidth, 0.0625rem) solid var(--sapList_BorderColor, #e4e4e4); }
  .lui-side-nav__footer--text {
    color: #32363a;
    color: var(--sapTextColor, #32363a);
    white-space: nowrap;
    width: auto;
    padding: 13px 20px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -ms-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%; }
  .lui-side-nav__footer--icon {
    cursor: pointer;
    padding: 13px 20px; }

:global(.lui-semiCollapsible) .fd-app__sidebar {
  -webkit-transition: width 0.1s linear;
  -moz-transition: width 0.1s linear;
  -ms-transition: width 0.1s linear;
  -o-transition: width 0.1s linear;
  transition: width 0.1s linear; }

:global(.lui-semiCollapsible) .lui-side-nav__footer--text {
  max-width: calc(100% - 50px); }

.fd-nested-list .fd-nested-list__title {
  display: inline-block;
  height: auto; }

.fd-nested-list__content.has-child .fd-nested-list__link {
  max-width: calc(100% - 2.5rem); }

:global(.semiCollapsed) .level-2 {
  display: none; }

:global(.semiCollapsed) .fd-app__sidebar {
  width: 2.75rem; }
  :global(.semiCollapsed) .fd-app__sidebar .lui-fd-side-nav-wrapper {
    min-width: auto;
    width: calc(2.75rem + 70px);
    padding-right: 70px;
    margin-right: -70px; }

:global(.semiCollapsed) .isBlocked .lui-fd-side-nav-wrapper {
  overflow: hidden !important; }

:global(.semiCollapsed) .lui-side-nav__footer--text {
  width: 0;
  opacity: 0;
  padding: 0;
  visibility: hidden; }

:global(.semiCollapsed) .lui-side-nav__footer--icon {
  padding-left: 14px;
  padding-right: 14px; }

:global(.semiCollapsed) .hasOnlyFooterText .fd-side-nav__main-navigation {
  height: 100%; }

:global(.lui-flyout-sublist) {
  position: fixed;
  left: 48px;
  width: 180px; }
  :global(.lui-flyout-sublist) .lui-flyout-sublist__wrapper {
    position: relative;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-box-shadow: 0 5px 20px 0 var(--fd-color-neutral-3), 0 2px 8px 0 var(--fd-color-neutral-2);
    -moz-box-shadow: 0 5px 20px 0 var(--fd-color-neutral-3), 0 2px 8px 0 var(--fd-color-neutral-2);
    box-shadow: 0 5px 20px 0 var(--fd-color-neutral-3), 0 2px 8px 0 var(--fd-color-neutral-2);
    border: 1px solid var(--fd-color-neutral-3);
    background: white;
    opacity: 0;
    animation-name: flyoutAnimation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards; }

@-webkit-keyframes flyoutAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes flyoutAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    :global(.lui-flyout-sublist) .lui-flyout-sublist__wrapper:before, :global(.lui-flyout-sublist) .lui-flyout-sublist__wrapper:after {
      display: block;
      position: absolute;
      top: 13px;
      content: '';
      width: 13px;
      border-style: solid;
      border-width: 8px 6.5px;
      border-color: transparent; }
    :global(.lui-flyout-sublist) .lui-flyout-sublist__wrapper:before {
      left: -14px;
      border-right-color: var(--fd-color-neutral-3); }
    :global(.lui-flyout-sublist) .lui-flyout-sublist__wrapper:after {
      left: -13px;
      border-right-color: white; }
  :global(.lui-flyout-sublist) li {
    border-top: 1px solid var(--fd-color-neutral-2); }
    :global(.lui-flyout-sublist) li:first-child {
      border: none; }
  :global(.lui-flyout-sublist) .fd-nested-list {
    display: block;
    position: relative;
    max-height: 190px;
    overflow-y: auto; }
  :global(.lui-flyout-sublist)__title {
    padding: 11px 12px 12px;
    text-transform: uppercase;
    margin: 0; }
  :global(.lui-flyout-sublist).has-bottom-position .lui-flyout-sublist__wrapper:before, :global(.lui-flyout-sublist).has-bottom-position .lui-flyout-sublist__wrapper:after {
    top: auto;
    bottom: 9px; }

/*# sourceMappingURL=x.map */</style>
