{#if node && node.badgeCounter} {#await node.badgeCounter.count()} {:then count} {#if
count > 0}
<span
  class="fd-counter fd-counter--notification fd-shellbar__counter--notification"
  aria-label="{node.badgeCounter.label}"
  >{count}</span
>
{/if} {:catch error} {/await} {/if}
<script>
  export let node;
</script>

<style type="text/scss">:global(.fd-shellbar__action) .fd-shellbar__counter--notification {
  top: 0; }

:global(.fd-shellbar__action) .fd-menu .fd-top-nav__icon {
  position: relative;
  margin-right: 8px;
  min-width: 16px;
  min-height: 16px;
  display: inline-block;
  vertical-align: middle; }
  :global(.fd-shellbar__action) .fd-menu .fd-top-nav__icon .fd-shellbar__counter--notification {
    top: -8px; }

:global(.fd-shellbar__action) .fd-product-switch__body--mobile .lui-product-switch__icon {
  position: relative; }
  :global(.fd-shellbar__action) .fd-product-switch__body--mobile .lui-product-switch__icon .fd-shellbar__counter--notification {
    top: -8px;
    right: 6px; }

:global(.fd-nested-list__link) .fd-counter--notification {
  transform: translate(0px, 0px) !important;
  margin-left: auto;
  min-width: 1.3rem;
  margin-right: -0.3rem; }

/*# sourceMappingURL=x.map */</style>
